// Colors
//--------------------------------------------

$colors: (
        primary: #3a007d,
        secondary: #368dd5,
        primary-contrast: #fff,
        secondary-contrast: #fff,
        pale-grey: #eef1f9,
  /* steel-grey */ pale-grey-contrast: #74747e,
        light-grey-blue: #a6afbf,
        light-grey-blue-contrast: #fff,
        steel-grey: #74747e,
        steel-grey-contrast: #fff,
        pale-grey-two: #f5f6fa,
  /* steel-grey */ pale-grey-two-contrast: #74747e,
        pale-grey-three: #f7f9fa,
  /* steel-grey */ pale-grey-three-contrast: #74747e,
        strong-grey: #5a5f68,
        strong-grey-contrast: #fff,
        cloudy-blue: #b9c8d9,
        cloudy-blue-contrast: #fff,
        deep-blue: #0f0f3d,
        deep-blue-contrast: #fff,
        success: #02dc94,
        success-contrast: #fff,
        info: #0081ff,
        info-contrast: #fff,
        warn: #f8cf0d,
        warn-contrast: #fff,
        danger: #ff5645,
        danger-contrast: #fff
);

:root {
  // generate css vars. We also generate a css var in the form of --${name}-rgb with the rgb values.
  // this enables applying opacity to css vars like rgba(--primary, 0.2)
  @each $var-name, $color in $colors {
    --#{$var-name}: #{$color};
    --#{$var-name}-rgb: #{red($color)}, #{green($color)}, #{blue($color)};
  }
}

// Fonts
//--------------------------------------------
$heading-font: 'Poppins', 'Roboto', 'Helvetica Neue', sans-serif !default;
$body-font: 'Inter', 'Roboto', 'Helvetica Neue', sans-serif !default;

// Easing Curves
//--------------------------------------------

$swift-ease-out-duration: 0.4s !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 0.3s !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 0.5s !default;
$swift-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1) !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 0.08s !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;

$material-enter-duration: 0.3s;
$material-enter-timing-function: cubic-bezier(0, 0, 0.2, 1);
$material-enter: all $material-enter-duration $material-enter-timing-function;

$material-leave-duration: 0.3s;
$material-leave-timing-function: cubic-bezier(0.4, 0, 1, 1);
$material-leave: all $material-leave-duration $material-leave-timing-function;
